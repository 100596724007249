<template>
  <div
    class="w-full flex flex-col items-center gap-10 p-4 md:p-unset"
  >
    <div class="w-full flex flex-col justify-center">
      <div class="flex flex-col items-center gap-y-2">
        <slot name="title"> </slot>
        <slot name="subtitle"></slot>
      </div>
    </div>
    <div class="w-full sm:w-[336px] xl:w-[32rem]">
      <slot></slot>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
